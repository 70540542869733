import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { SocketContext } from '../context/socket';
import { MdTroubleshoot } from 'react-icons/md';

export default function ChatContainer({ currentChat, currContact, contacts, setContacts }) {
  const socket = useContext(SocketContext);

  const scrollRef = useRef();

  // Update contact list function
  let updateContactList = async (msg) => {
    let newSender = msg.sender;
    let newNumber = msg.number;
    let newName = msg.name;
    let lastMessage = msg.lastMessage;
    let keywords = msg.keywords;
    let newAutoResponse = msg.autoResponse;
    let isNewContact = true;

    const newState = contacts.filter((obj) => {
      if (obj.number === newNumber) {
        isNewContact = false;
        return true;
      } else {
        return false;
      }
    });
    let botUserId = uuidv4();

    const newContact = {
      autoResponse: true,
      lastMessage: lastMessage,
      lastUse: Date.now(),
      name: newName,
      number: newNumber,
      phoneId: newSender,
      new: true,
      _id: botUserId, // or some other ID generation logic
    };

    setContacts(currentContacts => {
      let updatedContacts = [...currentContacts];
      
      const contactIndex = updatedContacts.findIndex(contact => contact.number === newNumber);
      const isNewContact = contactIndex === -1;
    
      if (isNewContact) {
        // No need to declare these fields again if they're not changed or used differently from msg
        const newContact = {
          autoResponse: true,
          createdAt: Date.now(),
          lastMessage: lastMessage || "",
          lastUse: Date.now(),
          name: newName || "",
          number: newNumber,
          threadId: "threadId",
          keywords: keywords,
          userId: "",
          _id: uuidv4(), // Creating a new UUID
        };
        updatedContacts.unshift(newContact); // Add to the beginning of the array
      } else {
        // Update the existing contact with new information
        updatedContacts[contactIndex] = {
          ...updatedContacts[contactIndex],
          lastMessage: lastMessage,
          lastUse: Date.now(),
          keywords: keywords,
          name: newName,           // Assuming this is updated from the msg object
          autoResponse: true, // Assuming this status might come from the update
          // Include any other fields from the msg object as needed
        };
        // Now, sort the array based on `lastUse` in descending order
        updatedContacts.sort((a, b) => b.lastUse - a.lastUse);
      }
      
      return updatedContacts;
    });
  }

  useEffect(() => {
    const handleNewMessage = (msg) => {
      const parsedMsg = JSON.parse(msg);
      if (parsedMsg.sender === currContact) {
        //setMessages((prev) => [...prev, { fromSelf: false, message: parsedMsg.message, date: new Date() }]);
      } else {
        updateContactList(parsedMsg);
      }
    };
    
    if (socket) {
      socket.on('message', handleNewMessage);
    }

    // Clean up the effect by removing the event listener
    return () => {
      if (socket) {
        socket.off('message', handleNewMessage);
      }
    };
  }, [socket, currContact, updateContactList]);

  // Returning JSX of the container
  return (
    <Container>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 0% 85% 15%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 0% 85% 15%;
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
      }
      .username {
        h3 {
          color: white;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 40%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #d1d1d1;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 70%;
        }
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        color: black;
        background-color: #dcf8c6;
      }
    }
    .received {
      justify-content: flex-start;
      .content {
        color: #3b3a30;
        background-color: whitesmoke;
      }
    }
    .status {
    }
    .black {
      color: black;
    }
    .deepskyblue {
      color: deepskyblue;
    }
  }
`;
