import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import Moment from "moment";

import { updateAutoResponse } from "../utils/APIRoutes";

export default function Contacts({ contacts, changeChat }) {
  //console.log(contacts)
  const theme = {
    borders: {
      default: "0.5px groove whitesmoke",
      radius: "13px",
    },
    // other theme properties...
  };

  const changeContactStatus = async (_id) => {
    try {
      let autoResponse;
      let element = document.getElementById(_id);
      // Get the computed style of the element
      let computedStyle = window.getComputedStyle(element);
      // Get the background color of the element
      var oldBackgroundColor = computedStyle.backgroundColor;
      if(oldBackgroundColor === "rgb(18, 140, 126)"){
        element.classList.remove('selected');
        element.classList.add('un-selected');
        autoResponse = false;
      }else{
        element.classList.remove('un-selected');
        element.classList.add('selected');
        autoResponse = true;
      }
      console.log((`${updateAutoResponse}/${_id}/${autoResponse}`))
      const data = await axios.post(`https://www.zaping.io:8445/autoresponse/${_id}/${autoResponse}`);
      console.log(data)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Container style={{ fontSize: '70%', width: '90%', height: '100%', float: 'right' }}>
        <div className="brand">
          <p>Clique no contato p/ Desativar/Ativar o automático:</p>
        </div>
        <div className="chat-contacts">
          {contacts.map((contact, index) => {
            if (!contact.threadId) {
              return null; // Don't render anything if there's no threadId
            }
            return (
              <div
                key={contact._id}
                id={contact._id}
                className={`contact ${
                  contact.autoResponse ? "selected" : ""
                }`}
                onClick={() => changeContactStatus(contact._id)}
              >
                <div className="chat-username new">
                  <h5 >
                    {contact.number.length === 36 || contact.name.length < 1 ? "" : contact.number + ' / ' + contact.name}
                      <p class="keywords">{contact.lastMessage}</p>
                    <p>{Moment(contact.lastUse).format("DD HH:mm")}</p>
                  </h5>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  :root {
    --border-style: 0.5px groove whitesmoke;
    --border-radius: 13px;
  }

  display: grid;
  grid-template-rows: 5% 95% 0%;
  overflow: auto;
  background-color: #212529;

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  .old {
    font-weight: 80 !important;
  }
  .brand {
    padding-top: 10px !important;
    padding-left: 20px !important;
    color: whitesmoke;
    p {
      font-size: 15px !important;
    }
  }
  .chat-contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.2rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #343a40;
        width: 0.1rem;
        border-radius: 13px; /* Rounds the borders */
      }
    }
    .contact {
      background-color: #343a40;
      height: auto !important;
      cursor: pointer;
      width: 90%;
      padding: 0;
      padding-left: 5px;
      margin-top: 0px;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      transition: 0.25s ease-in-out;
      .chat-username {
        padding-top: 5px !important;
        h5 {
          color: whitesmoke;
          font-size: 15px !important;
          p {
            color: whitesmoke;
            font-size: 13px !important;
          }
        }
      }
      p {
        color: whitesmoke;
      }
    }
    .selected {
      background-color: #128c7e;
    }
    .un-selected {
      background-color: #343a40;
    }
  }

  .current-user {
    background-color: #0d0d30;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .chat-username {
      h5 {
        color: #F8F8FF;
        font-size: 1rem;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .chat-username {
        h5 {
          font-size: 1rem;
        }
      }
    }
  }

  button {
    color: whitesmoke !important;
  }

  .item {
    color: whitesmoke !important;
    height: 24px;
  }

  .keywords {
    background-color: transparent !important;
    font-size: 80%;
    color: whitesmoke !important;
    display: flex;
    padding-left: 5px;
    height: auto;
    font-style: italic;
  }
`;
