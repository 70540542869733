import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

import { BsGlobe2 } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { BsCalendar2Date } from "react-icons/bs";

import { MdOutlineShoppingCart } from 'react-icons/md';
import { BiCog } from 'react-icons/bi';
import { ImExit } from "react-icons/im";
import { IconContext } from "react-icons";
import { BsFileBarGraphFill } from "react-icons/bs";
import { TiThMenuOutline } from "react-icons/ti";
import { SiAuth0 } from "react-icons/si";
import { RiRobot3Line } from "react-icons/ri";
import { IoMdAlarm } from "react-icons/io";

import logo from "../assets/logoZaping.png";

let Theme = 'dark' | 'dark';

const themes = {
  light: {
    sidebar: {
      backgroundColor: '#ffffff',
      color: '#607489',
    },
    menu: {
      menuContent: '#fbfcfd',
      icon: '#0098e5',
      hover: {
        backgroundColor: '#c5e4ff',
        color: '#44596e',
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: '#212529',
      color: 'whiteSmoke',
    },
    menu: {
      menuContent: '#212529',
      icon: 'whiteSmoke',
      hover: {
        backgroundColor: '#343a40',
        color: '#b6c8d9',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default function Playground() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [rtl, setRtl] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [theme, setTheme] = useState('dark');

  const handleRTLChange = (e) => {
    setRtl(e.target.checked);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.checked ? 'dark' : 'dark');
  };

  const handleImageChange = (e) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles = {
    root: {
      fontSize: '16px',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#343a40',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  useEffect(() => {
    function handleResize() {
      setCollapsed(window.innerWidth < 768);
    }
    
    window.addEventListener('resize', handleResize);
  
    // Call the function to set the initial state
    handleResize();
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (

    <div style={{ display: 'flex', height: '100%', direction: rtl ? 'rtl' : 'ltr' }}>
    {/* Toggle Button */}
      <button
        style={{
          position: 'fixed',
          zIndex: 10,
          top: '1px', // Positioned at the top
          left: !collapsed && toggled ? '250px' : '1px', // Adjust based on sidebar width
          cursor: 'pointer',
          color: 'whitesmoke',
          background: 'transparent',
          border: 'none',
          borderRadius: '5px',
          padding: '10px',
          fontSize: '20px',
          transition: 'left 0.3s ease', // Smooth transition for moving button
          display: collapsed ? 'block' : 'none', // Hide on desktop where collapsed is false
        }}
        onClick={() => setToggled((prev) => !prev)}
      >
        {toggled ? <ImExit /> : <TiThMenuOutline />}
      </button>

      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: 1, marginBottom: '32px' }}>
          <div style={{ padding: '24px', paddingLeft: '40%', marginBottom: '8px' }}>
            <img id="logo-cg" src={logo} alt="Zaping" style={{ width: "50px" }} />
          </div>
            <IconContext.Provider value={{ size: "1.3em"}}>
              <Menu menuItemStyles={menuItemStyles}>      
                <MenuItem component={<a href={`https://zaping.io:8445/dash`}>Dashboard</a>} icon={<BsFileBarGraphFill />}>Dashboard</MenuItem>
                <MenuItem component={<a href={`https://zaping.io:8445/assistant`}>Assistente</a>} icon={<RiRobot3Line />}>Assistente</MenuItem>          
                <MenuItem component={<a href={`https://zaping.io:8445/chat`}>Chat do site</a>} icon={<IoChatboxEllipsesOutline />}>Chat do site</MenuItem>
                <MenuItem component={<a href={`https://zaping.io:8445/trigger`}>Follow Up</a>} icon={<BsCalendar2Date />}>Follow Up</MenuItem>
                <MenuItem component={<a href={`https://zaping.io:8445/reminder`}>Lembrete da Agenda</a>} icon={<IoMdAlarm />}>Lembrete da Agenda</MenuItem>
                <SubMenu label="Conexão" icon={<BsGlobe2 />}>
                  <MenuItem component={<a href={`https://zaping.io:8445/qrcode`}>QRCode</a>} icon={<FaWhatsapp />}>QRCode</MenuItem>
                  <MenuItem component={<a href={`https://zaping.io:8445/widget-data`}>GPT no seu site</a>} icon={<IoChatboxEllipsesOutline />}>GPT no seu site</MenuItem>
                  <MenuItem component={<a href={`https://zaping.io:8445/auth0`}>Google</a>} icon={<SiAuth0 />}>Google</MenuItem>
                </SubMenu>
                <MenuItem component={<a href={`https://zaping.io:8445/settings`}>Configuração</a>} icon={<BiCog />}>Configuração</MenuItem>
              </Menu>            
              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem component={<Link to="/" />} icon={<ImExit />}>Sair</MenuItem>
              </Menu>            
            </IconContext.Provider>            
          </div>
      </div>
      </Sidebar>

      <main>

      </main>
    </div>
  );
};