import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router";

import styled from "styled-components";

import axios from "axios";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { IconContext } from "react-icons";
import { IoMdAdd } from "react-icons/io";
import { RiCloseCircleLine } from "react-icons/ri";

import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import './style/trigger.css';

import Playground from "../components/Playground";

let userId = getStorage("_userId");

function getStorage(prm) {
  let _prm = window.localStorage.getItem(prm);
  if(_prm === "null"){
  }else{
    if(_prm) {
      return _prm;
    }
  }
}

const redirectLogin = () => {
  const newPort = 8445;
  const newPath = '/';
  window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
};

let record;
let _groupsList;

export default function Group() {
  const navigate = useNavigate();         
  const { t } = useTranslation();
  const [groupsList, setGroupList] = useState([]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const url = `https://www.zaping.io:8445/query_group/${userId}`;
        console.log('Making request to URL:', url);
        const response = await axios.get(url);
        // Assuming the existence of `record` in your response
        record = response.data;
    
        if (!record) {
          toast.error('Grupos Inexistentes!', {autoClose: 3000});
          //redirectLogin();
          return;
        }else{
          console.log(record)
          setGroupList(record || []);      
        }
      } catch (error) {
        console.error('Fetch error:', error);
        toast.error('Erro ao buscar dados!');
        //redirectLogin();
      }
    };
    
    if (userId) {
      fetchData();      
    } else {
      console.log('User ID is not set. Redirecting to login.');
      redirectLogin();
    }

  }, [userId, navigate]);

  const addGroup = (e) => {
    e.preventDefault();
    
    let updatedGroupList = [...groupsList];
    let newGroup = {description: "", message: ""}
    updatedGroupList.push(newGroup);
    setGroupList(updatedGroupList);
  };
  
  const deleteGroup = (index, e) => {
    e.preventDefault();
  
    let updatedGroupList = [...groupsList];
    updatedGroupList.splice(index, 1); // Removes 1 element at the given index
    setGroupList(updatedGroupList);
    console.log(updatedGroupList)
  };

  const Tooltip = ({ children, text }) => {
    return (
      <div className="tooltip-container">
        {children}
        <span className="tooltip-text">{text}</span>
      </div>
    );
  };

  function updateDescription(value, index) {
    let updatedGroupsList = [...groupsList];
    updatedGroupsList[index].description = value.description;
    setGroupList(updatedGroupsList); // This was missing
  }

  function updateMessage(value, index) {
    let updatedGroupsList = [...groupsList];
    updatedGroupsList[index].message = value.message;
    setGroupList(updatedGroupsList); // This was missing
  }

  async function onSubmit(e) {
    e.preventDefault();
    
    const updatedGroupList = groupsList.map(item => ({
      ...item,
      userId: userId
    }));

    let config = {
      method: 'post',
      url: `https://www.zaping.io:8445/update_groups/${userId}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: updatedGroupList
    };
    const response = await axios(config);
    console.log(response)
    if(response?.statusText === "OK"){
      toast.success("Atualizado com sucesso!", {autoClose: 3000})
    }else{
      toast.error('ERRO ao atualizar!', {autoClose: 3000});
    }
  }

  // This following section will display the form that takes the input from the user.
  return (
    <Container>

      <div className="container-add">
        <div className="playground">
          <Playground />
        </div>

        <div>
          <form onSubmit={onSubmit}>
            <br />
            <div className="form-group">
              <div className="row">   
                <div className="col-12">
                  <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                    <Tooltip text="Clique para incluir.">
                      <button id="butAddField" onClick={(e) => addGroup(e)} className="butAddField"><IoMdAdd />Incluir</button>
                    </Tooltip>
                  </IconContext.Provider>
                </div>    
              </div>

              <div className="row">
                <div className="col-1">
                </div>         
                <div className="col-4">
                  <label>Descrição</label>
                </div>    
                <div className="col-7">
                  <label>Mensagem</label>
                </div>    
              </div>    

              {groupsList?.map((group, index) => (
                <div key={index}>

                  <div className="row">
                    <div className="col-1">
                      <Tooltip text="Clique para excluir.">
                        <button onClick={(e) => deleteGroup(index, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                          <RiCloseCircleLine className="icon" />
                        </button>           
                      </Tooltip>
                    </div>
                    
                    <div className="col-4">
                      <input type="text" 
                              id="description"
                              value={group?.description} 
                              onChange={(e) => {
                                updateDescription( { description: e.target.value}, index );
                              }} 
                              className="form-control" />
                    </div>
              
                    <div className="col-7">                  
                      <textarea
                        type="text"
                        className="form-control"
                        value={group?.message} 
                        style={{height: '90px'}}
                        onChange={(e) => updateMessage( { message: e.target.value}, index )} 
                        placeholder="ex: Olá {nome}! Passando aqui para lembrar que..." 
                      />                              
                    </div>
                  </div>

                </div>
              ))}              

              <div>
                <br />
                <input
                  type="submit"
                  value="Salvar"
                  id="butSave"
                  className="btn btn-primary shadow1"
                />
              </div>

            </div>
          </form>             
        </div>
 
        <ToastContainer />

      </div>
    </Container>

  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .playground  {
    position: sticky; /* Set position to sticky */
    top: 0; /* Stick to the top */
    z-index: 999999999;
    height: 100vh; /* Set specific height */
    background-color: #212529;
  }
  .container-add {
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20% 80%; /* Use fractions of available space */
    @media screen  and (max-width: 1080px) {
      grid-template-columns: 5% 95%; /* Adjust for different screen size */
      font-size: 75%;
      #alertPhone {
        font-size: 100%;
      }
    }
  }
`;
