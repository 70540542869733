import React, { useEffect } from 'react';

const ZapingWidgetClient = () => {
    useEffect(() => {
        // Dynamically create and append the div container
        const div = document.createElement('div');
        div.id = 'zaping-widget';
        div.style.zIndex = '999999999';
        document.body.appendChild(div);
        
        // Dynamically create and append the script
        const script = document.createElement('script');
        script.src = 'https://zaping.io/dist/main.js';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);
        
        // Set the global variable and dispatch the event once the script is loaded
        script.onload = () => {
            window.ReactWidgetUserId = '65d4c2baa458de75b38c0ef4';
            window.dispatchEvent(new Event('ReactWidgetUserIdAvailable'));
        };

        // Clean up to avoid memory leaks when the component unmounts
        return () => {
            document.body.removeChild(div);
            document.body.removeChild(script);
        };
    }, []);

    return null; // This component doesn't render anything itself
};

export default ZapingWidgetClient;
