import React from "react";
import { Routes, Route } from "react-router-dom";

import Dash from "./pages/Dash";
import Chat from "./chat/Chat";
import CatalogList from "./pages/catalog-list";
import CatalogAdd from "./pages/catalog-add";
import CatalogChange from "./pages/catalog-change";
import Assistant from "./pages/assistant";
import Trigger from "./pages/trigger";
import Reminder from "./pages/reminder";
import QRCode from "./pages/qrcode";
import Widget from "./pages/widget-data";
import Auth2 from "./pages/auth0";
import Redirect from "./pages/redirect";
import Settings from "./pages/settings";
import Login from "./pages/Login";

import Home from "./home/LandingPage";

import Broker from "./pages/broker";
import Group from "./pages/group";

import {SocketContext, socket} from './context/socket';

export default function App() {
  return (
    <SocketContext.Provider value={socket}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/dash" isPrivate element={<Dash />} socket={socket} />
          <Route exact path="/chat" isPrivate element={<Chat />} socket={socket} />
          <Route exact path="/catalog-list" isPrivate element={<CatalogList />} />
          <Route exact path="/catalog-add" isPrivate element={<CatalogAdd />} />
          <Route exact path="/catalog-change/:id" isPrivate element={<CatalogChange />} />
          <Route exact path="/assistant" isPrivate element={<Assistant />} />
          <Route exact path="/trigger" isPrivate element={<Trigger />} />
          <Route exact path="/reminder" isPrivate element={<Reminder />} />
          <Route exact path="/qrcode" isPrivate element={<QRCode />} />
          <Route exact path="/widget-data" isPrivate element={<Widget />} />
          <Route exact path="/auth0" isPrivate element={<Auth2 />} />
          <Route exact path="/redirect" isPrivate element={<Redirect />} />
          <Route exact path="/settings" isPrivate element={<Settings />} />
          <Route exact path="/broker" isPrivate element={<Broker />} />
          <Route exact path="/group" isPrivate element={<Group />} />
        </Routes>
    </SocketContext.Provider>
  );
}
