import React from "react";

//import { io } from "socket.io-client";
import io from 'socket.io-client';
const SOCKET_URL = "https://zaping.io:8444";
export const socket = io.connect(SOCKET_URL, { transport: ['websocket', 'pooling'] });

export const SocketContext = React.createContext();

socket.on('connect', function(){
console.log(socket);
console.log('connected');
  let param = {userId: window.localStorage.getItem('_userId')}
  socket.send(param);
});
