import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import CChatInput from "./CChatinput";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Moment from 'moment';

import {SocketContext} from '../../context/socket';

let updateMessage = false;
export default function CChatContainer({ currentChat, socket, currContact, contacts, setContacts, autoResponse }) {
  socket = useContext(SocketContext);
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);

  const scrollRef = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
      navigate("/login");
    }else{
      if(_prm) {
        return _prm;
      }else{
        navigate("/login");
      }
    }
  }  

  let userId = getStorage("_userId");

  useEffect(() => {
    async function fetchData() {
      if(currentChat){
        let response;
        if(currentChat.number){
          console.log('https://www.zaping.io:8445/message-get/' + userId + "/" + currentChat.number)
          response = await axios.get('https://www.zaping.io:8445/message-get/' + userId + "/" + currentChat.number);
        }else{
          console.log('https://www.zaping.io:8445/message-get/' + userId + "/" + currentChat)
          response = await axios.get('https://www.zaping.io:8445/message-get/' + userId + "/" + currentChat);
        }
        console.log(response.data)

        let status;
        let blackColor;
        let fromYou;
        const projectedMessages = response.data.map((msg) => {
          if(msg.sender === msg.userId){
            fromYou = true;
            status = "✓";
            blackColor = true;
            if(msg.status === "sent"){
            }else{
              if(msg.status === "delivered" || msg.status === "read"){
                status = "✓✓";
                if(msg.status === "read"){
                  blackColor = false;
                }
              }
            }
          }else{
            fromYou = false;
            status = "";
            blackColor = true;
          }

          return {
            fromSelf: fromYou,
            message: msg.message,
            date: msg.date,
            at: msg.at,
            status: status,
            blackColor: blackColor
          };
        });
        setMessages(projectedMessages);
      }
    }

    fetchData();
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    let messageData = {
                        instanceKey: userId,
                        type: "message",
                        origin: "chat",
                        body: {
                              pushName: "",
                              key: { remoteJid: currentChat.number},
                              message: { conversation: msg }
                              }
                      }    

    try {
      let url = "https://www.zaping.io:8445/send-to-widget";
      let response = await fetch(url, {
                              method: "POST",
                              body: JSON.stringify(messageData),
                              headers: {
                                'Content-Type': 'application/json'
                              },
                            });
      if(response.status === 200){
        let apply = await response.json();
        if(apply.success){
          let at = Date.now();
          let atDate = Date.now();
          const msgs = [...messages];
          msgs.push({ fromSelf: true, message: msg, date: atDate, at: at, status: "✓", blackColor: true});
          setMessages(msgs);
        }else{
          toast.error('Cliente desconectado!', {autoClose: 3000});
        }
      }        
    } catch (error) {
      console.error('Error sending message:', error);
    }

  };

  let updateContactList = (msg) => {
    let fromSelf;
    if(msg.status === "sent"){
      fromSelf = false;
      msg.sender = msg.number;
    }else{
      if(msg.status === "receive"){
        fromSelf = true;      
      }
    }
    let newSender = msg.sender;
    let found = false;
    const newState = contacts.map(obj => {
      if (obj.number === newSender) {
        found = true;
        return {...obj, date: new Date(), new: true};
      }

      return obj;
    });

    if(found){
      console.log('found!')
      found = false;

      //setContacts(newState);
      console.log(messages)

      setMessages((prev) => [...prev, { fromSelf: fromSelf, message: msg.message, date: new Date(), at: Date.now(), status: "", blackColor: true }]);
    }else{
      let botUserId = msg.botUserId;
      newState.splice( 0, 0, {date: new Date(), new: true, number: newSender, _id: botUserId, autoResponse: true } );
      setContacts(newState);
      let status;
      let fromYou;
      let blackColor;
      if(msg.sender === msg.userId){
        fromYou = true;
        status = "✓";
        blackColor = true;
        if(msg.status === "sent"){
        }else{
          if(msg.status === "delivered" || msg.status === "read"){
            status = "✓✓";
            if(msg.status === "read"){
              blackColor = false;
            }
          }
        }
      }else{
        fromYou = false;
        status = "";
        blackColor = true;
      }
      updateMessage = true;

      currentChat = msg.number;

      setMessages([{ fromSelf: fromYou, message: msg.message, date: new Date(), at: Date.now(), status: "", blackColor: true }]);
    }
  };

  useEffect(() => {
    const handleNewMessage = (msg) => {
      const parsedMsg = JSON.parse(msg);
      if(parsedMsg.number.length > 15){
        if (parsedMsg.sender === currContact) {
          let fromSelf = false;
          if(parsedMsg.sender === userId){
            fromSelf = true
          }
          setMessages((prev) => [...prev, { fromSelf: fromSelf, message: parsedMsg.message, date: new Date() }]);
        } else {
          updateContactList(parsedMsg);
        }
      }
    };
    if (socket) {
      socket.on('message', handleNewMessage);
    }

    // Clean up the effect by removing the event listener
    return () => {
      if (socket) {
        socket.off('message', handleNewMessage);
      }
    };
  }, [socket, currContact, updateContactList]);

  useEffect(() => {
    if(updateMessage){
      arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
      <Container>
        <div className="chat-header">
        </div>
        <div className="chat-messages" style={{ backgroundColor: '#212529' }}>
          {messages.map((message) => {
            return (
              <div ref={scrollRef} key={uuidv4()}>
                <div
                  className={`message ${
                    message.fromSelf ? "sended" : "received"
                  }`}
                >
                  <div className="content " style={{ fontSize: '0.9rem !important' }}>
                    <h5>{message.message}
                      <p></p>
                      <p className="dateAndTime">{Moment(message.date).format('DD-MM HH:mm')}
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <CChatInput handleSendMsg={handleSendMsg} autoResponse={autoResponse} />
        <ToastContainer />
      </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 0% 85% 15%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 0% 85% 15%;
  }

  .dateAndTime {
    font-size: 0.8rem !important;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
      }
      .username {
        h3 {
          color: white;
        }
      }
    }
  }
  .chat-messages {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    font-size: 0.9rem; 
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    h5 {
      padding-top: 10px !important;
      font-size: 1rem !important; 
    }
    .message .content {
      font-size: 0.9rem !important;
    }
    .message {
      display: flex;
      align-items: center;
      padding-bottom: 0.3rem;
      .content {
        max-width: 40%;
        overflow-wrap: break-word;
        padding: 0.3rem;
        font-size: 0.9rem !important;
        border-radius: 1rem;
        color: #d1d1d1;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 70%;
        }
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        color: black;
        background-color: #dcf8c6;
      }
    }
    .received {
      justify-content: flex-start;
      .content {
        padding: 0.3rem;
        color: #3b3a30;
        background-color: whitesmoke;
      }
    }
    .black {
      color: black;
    }
    .deepskyblue {
      color: deepskyblue;
    }
  }
`;
