import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router";
import styled from "styled-components";

import "react-toastify/dist/ReactToastify.css";

import './style/widget-data.css';

import Playground from "../components/Playground";

export default function Edit() {
  const navigate = useNavigate();
  
  const [form, setForm] = useState({
    code: "",
  });
  
  let _userId = getStorage("_userId");

  const codeString = `<div id="zaping-widget" style="z-index: 999999999 !important;"></div>
<script crossorigin src="https://zaping.io/dist/main.js"></script>
<script>
  window.ReactWidgetUserId = '` + _userId + `';
  window.dispatchEvent(new Event('ReactWidgetUserIdAvailable'));
</script>`;
  form.code = codeString;

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
      navigate("/login");
    }else{
      if(_prm) {
        return _prm;
      }else{
        navigate("/login");
      }
    }
  }
  
  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  // This following section will display the form that takes the input from the user.
  return (
    <Container>

      <div className="container-add usuario-inclui">
        <Playground />

        <div>
            <br />
            <div className="form-group">
              <br />
              <br />
              <div className="col-12">
                <label htmlFor="code">Copie o código-fonte abaixo e cole dentro da tag 'body' de sua página html para inserir o Assistente GPT no seu site: </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="code"
                  value={form.code}
                />
                <br />
              </div>

              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
        </div>

      </div>
    </Container>

  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .container-add {
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 1fr 3fr; /* Use fractions of available space */
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 1fr 2fr; /* Adjust for different screen size */
    }
  }
`;
