import React, { useState } from "react";
import styled from "styled-components";
import { IoMdSend } from "react-icons/io";

export default function CChatInput({ handleSendMsg, autoResponse }) {
  const [msg, setMsg] = useState("");
  
  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  return (
    <Container>  
      <form className="input-container" style={{ backgroundColor: 'black'}} onSubmit={(event) => sendChat(event)}>
        <input
          id="inputText"
          type="text"
          placeholder="Digite a resposta."
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
        />
        <button id="send" type="submit" className="button-send shadow1">
          <IoMdSend />
        </button>
      </form>  

      <div>
      </div>      
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  align-items: center;
  background-color: #212529;
  grid-template-columns: 99% 1%;
  padding: 0 1rem;
  padding-bottom: 10px !important;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    padding: 0 1rem;
    gap: 1rem;
  }
  #inputText {
    background-color: black !important;
  }
  .button-autoresponse {
    background-color: transparent !important;
    border-style: none;
    width: auto;
  }
  .butAct {
    font-size: 48px; 
    background-color: transparent !important;
    color: #128c7e;
  }
  .butDeact {
    font-size: 48px; 
    background-color: transparent !important;
    color: red;
  }
  .input-container {
    padding-top: 0px;
    margin-top: 0px;
    width: 100%;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: #ffffff34;
    input {
      width: 90%;
      height: 60%;
      background-color: transparent;
      color: white;
      border: none;
      padding-top: 0.5rem;
      padding-left: 1rem;
      font-size: 1.2rem;

      &::selection {
        background-color: #9a86f3;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      padding: 0.3rem 2rem;
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #128c7e;
      border: none;
      gap: 1rem;
      @media screen and (min-width: 720px) and (max-width: 1080px) {
        svg {
          font-size: 1rem;
        }
      }
      svg {
        font-size: 2rem;
        color: white;
      }
    }
  }

  .file {
    font-family: sans-serif;
    text-align: center;
    display: flex;
  }

  input[type="file"] {
      display: none;
  }
  .custom-file-upload {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 12px 12px;
      padding-top: 60%;
      cursor: pointer;
      background-color: transparent;
      border-color: transparent;
      border-radius: 5px;
  }

  .file-preview {
    margin: 0 10px;
  }

  .shadow1 {
    -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.91);
    -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.91);
    box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.91);
  }
`;
