import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import styled from "styled-components";

import CChatContainer from "./components/CChatContainer";
import CContacts from "./components/CContacts";
import Playground from "../components/Playground";

import {SocketContext} from '../context/socket';

export default function Chat() {
  let socket = useContext(SocketContext);

  const navigate = useNavigate();

  const [currContact, setCurrContact] = useState("");
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [autoResponse, setAutoResponse] = useState(undefined);

  useEffect(() => {
    if (window.localStorage.getItem('_userId')) {
      setCurrentUser(window.localStorage.getItem('_userId'));
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    async function fetchData() {
      if (currentUser) {
        const respBotUser = await axios.get('https://www.zaping.io:8445/init-chat/' + currentUser);
        const filteredData = respBotUser.data.filter(item => item.number.length > 15);
        setContacts(filteredData);

        let currChat = respBotUser?.data[0]?.number || [];
        setCurrentChat(currChat);
        setAutoResponse(respBotUser?.data[0]?.autoResponse || true)
      }
    }

    fetchData();
  }, [currentUser, socket]);

  const handleChatChange = (chat) => {
    setCurrContact(chat.number);
    setCurrentChat(chat.number);
    console.log(chat)
  };

  return (
    <div>
      <Container>
        <div className="container-chat">
          <Playground />

          <CChatContainer currentChat={currentChat} socket={socket} currContact={currContact} contacts={contacts} setContacts={setContacts} />      
          <CContacts contacts={contacts} changeChat={handleChatChange} autoResponse={autoResponse} />
        </div>
      </Container>
    </div>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .container-chat {
    height: 100vh;
    width: 100vw;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20% 55% 25%;
    @media screen  and (max-width: 1080px) {
      grid-template-columns: 5% 60% 35%; /* Adjust for different screen size */      
    }
  }
`;
