import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from "react-router";

import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import Playground from '../components/Playground';
import './style/settings.css';

let initialBusinessHours = [
                            'XXXXXXXXXXXXXXXXXXXXXXXX', // Sunday - Closed
                            'XXXXXXXXXXXXXXXXXXXXXXXX', // Monday
                            'XXXXXXXXXXXXXXXXXXXXXXXX', // Tuesday
                            'XXXXXXXXXXXXXXXXXXXXXXXX', // Wednesday
                            'XXXXXXXXXXXXXXXXXXXXXXXX', // Thursday
                            'XXXXXXXXXXXXXXXXXXXXXXXX', // Friday
                            'XXXXXXXXXXXXXXXXXXXXXXXX', // Saturday
                          ];

let initialGptHours = [
                        'OOOOOOOOOOOOOOOOOOOOOOOO', // Sunday
                        'OOOOOOOOOOOOOOOOOOOOOOOO', // Monday
                        'OOOOOOOOOOOOOOOOOOOOOOOO', // Tuesday
                        'OOOOOOOOOOOOOOOOOOOOOOOO', // Wednesday
                        'OOOOOOOOOOOOOOOOOOOOOOOO', // Thursday
                        'OOOOOOOOOOOOOOOOOOOOOOOO', // Friday
                        'OOOOOOOOOOOOOOOOOOOOOOOO', // Saturday
                      ];

const Settings = ({ locale = 'pt-BR' }) => {
  const navigate = useNavigate();          

  const [form, setForm] = useState({
    agendaDuration: "",
    agendaHours: "",
    gptHours: ""
  });

  const [agendaHours, setBusinessHours] = useState(initialBusinessHours);
  const [gptHours, setGptHours] = useState(initialGptHours);

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
    }else{
      if(_prm) {
        return _prm;
      }else{
      }
    }
  }
  
  const userId = getStorage("_userId");

  const formattedDays = useMemo(() => {
    const formatter = new Intl.DateTimeFormat(locale, { weekday: 'short' });
    const baseDate = new Date(2021, 0, 3); // Use a known Sunday
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(baseDate);
      date.setDate(baseDate.getDate() + i);
      const dayLabel = formatter.format(date).replace(".", "");
      daysOfWeek.push(dayLabel);
    }
    return daysOfWeek;
  }, [locale]);

  const formattedGptDays = useMemo(() => {
    const formatter = new Intl.DateTimeFormat(locale, { weekday: 'short' });
    const baseDate = new Date(2021, 0, 3); // Use a known Sunday
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(baseDate);
      date.setDate(baseDate.getDate() + i);
      const dayLabel = formatter.format(date).replace(".", "");
      daysOfWeek.push(dayLabel);
    }
    return daysOfWeek;
  }, [locale]);  

  const toggleHour = (dayIndex, hourIndex) => {
    setBusinessHours((prevHours) =>
      prevHours.map((day, dIndex) =>
        dIndex === dayIndex
          ? day.split('').map((hour, hIndex) => (hIndex === hourIndex ? (hour === 'X' ? 'O' : 'X') : hour)).join('')
          : day
      )
    );
  };

  const toggleGptHour = (dayIndex, hourIndex) => {
    setGptHours((prevHours) =>
      prevHours.map((day, dIndex) =>
        dIndex === dayIndex
          ? day.split('').map((hour, hIndex) => (hIndex === hourIndex ? (hour === 'X' ? 'O' : 'X') : hour)).join('')
          : day
      )
    );
  };

  const hoursHeader = Array.from({ length: 24 }, (_, index) => index);
  
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  useEffect(() => {
    //query_settings

    async function fetchData() {
      const response = await fetch('https://www.zaping.io:8445/query_settings/' + userId);
      if (!response.ok) {
        toast.error('ERRO!');
        return;
      }
      let record = await response.json();
      if (record) {
        console.log(record)
        const _form = {
                  agendaDuration: record?.agendaDuration || ''
                }
        setForm(_form);
        
        if(record?.agendaHours){
          if(record?.agendaHours.length > 0){
            setBusinessHours(record.agendaHours);
          }
        }
        if(record?.gptHours){
          if(record?.gptHours.length > 0){
            setGptHours(record.gptHours);
          }
        }        

      }

    }

    fetchData();

  }, [userId, navigate]);
  
  async function handleSubmit(e) {
    e.preventDefault();

    form.agendaHours = agendaHours;
    form.gptHours = gptHours;

    try {
      let response = await fetch("https://www.zaping.io:8445/update_settings/" + userId, {
        method: "POST",
        body: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if(response.status === 200){
        toast.success("Configuração atualizada com sucesso!", {autoClose: 3000})
      }
    }
    catch (error) {
      console.error(error);
    }    
  }

  return (
    <Container>
      <div className="container-add">
        <div className="playground">
          <Playground />
        </div>

        <div>
          <br />
          <label>Janela para Agendamento:</label>
          <div>
            <div style={{ color: 'whiteSmoke' }}>
              <BusinessHoursGrid>
                <HeaderRow>
                  <DayLabelStub />
                  {hoursHeader.map((hour) => (
                    <HourHeader key={hour}>{hour}</HourHeader>
                  ))}
                </HeaderRow>
                {agendaHours.map((dayHours, dayIndex) => (
                  <BusinessDay key={dayIndex}>
                    <DayLabel>{formattedDays[dayIndex]}</DayLabel>
                    {dayHours.split('').map((hour, hourIndex) => (
                      <HourToggle
                        key={hourIndex}
                        isOpen={hour === 'O'}
                        onClick={() => toggleHour(dayIndex, hourIndex)}
                      >
                        {hour}
                      </HourToggle>
                    ))}
                  </BusinessDay>
                ))}
              </BusinessHoursGrid>
            </div>
          </div>
          <br />

          <div className="same-line">
            <label htmlFor="agendaDuration">Duração do Agendamento (minutos):</label>
            <div className="col-1">            
              <input
                type="text"
                className="form-control"
                id="agendaDuration"
                value={form.agendaDuration}
                onChange={(e) => updateForm({ agendaDuration: e.target.value })}
                placeholder="0"
              />
            </div>
          </div>

          <br />
          <label>Horário de Funcionamento do Assistente GPT:</label>
          <div>
            <div style={{ color: 'whiteSmoke' }}>
              <BusinessHoursGrid>
                <HeaderRow>
                  <DayLabelStub />
                  {hoursHeader.map((hour) => (
                    <HourHeader key={hour}>{hour}</HourHeader>
                  ))}
                </HeaderRow>
                {gptHours.map((dayHours, dayIndex) => (
                  <BusinessDay key={dayIndex}>
                    <DayLabel>{formattedGptDays[dayIndex]}</DayLabel>
                    {dayHours.split('').map((hour, hourIndex) => (
                      <HourToggle
                        key={hourIndex}
                        isOpen={hour === 'O'}
                        onClick={() => toggleGptHour(dayIndex, hourIndex)}
                      >
                        {hour}
                      </HourToggle>
                    ))}
                  </BusinessDay>
                ))}
              </BusinessHoursGrid>
            </div>
          </div>          

          <div>
            <br />
            <br />
            <button className="btn btn-primary shadow1" onClick={(e) => handleSubmit(e)}>Salvar</button>
          </div>
          
        </div>   

        <ToastContainer />
 
      </div>
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  max-width: 100%; 
  box-sizing: border-box;
  .playground {
    position: sticky; /* Set position to sticky */
    top: 0; /* Stick to the top */
    z-index: 999999999;
    height: 100vh; /* Set specific height */
    background-color: #212529;
    max-width: 100%; 
    box-sizing: border-box;  
  }

  .same-line {
    display: flex;
    align-items: center; /* Vertically aligns items in the center */
    gap: 10px; /* Adjust the space between label and input */
    max-width: 100%; 
    box-sizing: border-box;  
  }

  .same-line label {
      margin: 0; /* Remove default margins */
      max-width: 100%; 
      box-sizing: border-box;    
  }

  .same-line input {
      flex: 1; /* Make the input take up the remaining space */
      max-width: 100%; 
      box-sizing: border-box;    
  }

  .container-add {
    overflow-y: scroll;
    height: 100vh;
    width: 100vw;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20% 80%; /* Use fractions of available space */
    max-width: 100%; 
    box-sizing: border-box;  
    @media screen and (max-width: 1080px) {
      grid-template-columns: 5% 95%; /* Adjust for different screen size */
      font-size: 75%;
      max-width: 100%; 
      box-sizing: border-box;    
      #alertPhone {
        font-size: 100%;
      }
    }
  }`;

const BusinessHoursGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%; 
  box-sizing: border-box;
`;

const HeaderRow = styled.div`
  display: flex;
  gap: 5px;
  max-width: 100%; 
  box-sizing: border-box;
`;

const DayLabelStub = styled.div`
  width: 50px; /* To align with DayLabel width */
  max-width: 100%; 
  box-sizing: border-box;
`;

const HourHeader = styled.div`
  width: 25px;
  text-align: center;
  font-weight: bold;
  color: whiteSmoke;
  max-width: 100%; 
  box-sizing: border-box;
`;

const BusinessDay = styled.div`
  display: flex;
  gap: 5px;
  max-width: 100%; 
  box-sizing: border-box;
`;

const DayLabel = styled.div`
  width: 50px;
  font-weight: bold;
  max-width: 100%; 
  box-sizing: border-box;
`;

const HourToggle = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border: transparent;
  background-color: ${(props) => (props.isOpen ? 'mediumseagreen' : 'crimson')};
  cursor: pointer;
  max-width: 100%; 
  box-sizing: border-box;
`;
