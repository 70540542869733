import * as React from 'react';
import { useEffect } from 'react'; // Import useEffect
import { useTranslation } from 'react-i18next';

import { $ } from 'jquery'; // Importing jQuery
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = [
  {
    title: 'Silver',
    price: '147',
    description: [
      'Até 2.500 mensagens (respondidas)',
      'Integração com Whats',
      'Widget (botão no site) com chat',
      'Reconhece texto e áudio',
      'Instruções do seu negócio',
      'Reconhece arquivos .csv e .json',
      'Integração à APIs',
      'Identifica estresse e avisa',
      'Aviso por palavras-chave',
      'Dashboard',
      'Catálogo de produtos online'
    ],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    link: "https://buy.stripe.com/5kAbLP4f1bM364g3cf?locale=pt"
  },
  {
    title: 'Gold',
    subheader: 'Recomendado',
    price: '397',
    description: [
      'Até 7.500 mensagens (respondidas)',
      'Integração com Whats',
      'Widget (botão no site) com chat',
      'Reconhece texto e áudio',
      'Instruções do seu negócio',
      'Reconhece arquivos .csv e .json',
      'Integração à APIs',
      'Identifica estresse e avisa',
      'Aviso por palavras-chave',
      'Dashboard',
      'Catálogo de produtos online'
    ],
    buttonVariant: 'contained',
    link: "https://buy.stripe.com/5kAaHLfXJ2btboAcMQ?locale=pt"
  },
  {
    title: 'Diamond',
    price: '947',
    description: [
      'Até 20.000 mensagens (respondidas)',
      'Integração com Whats',
      'Widget (botão no site) com chat',
      'Reconhece texto e áudio',
      'Instruções do seu negócio',
      'Reconhece arquivos .csv e .json',
      'Integração à APIs',
      'Identifica estresse e avisa',
      'Aviso por palavras-chave',
      'Dashboard',
      'Catálogo de produtos online'
    ],
    buttonVariant: 'outlined',
    link: "https://buy.stripe.com/14k3fjbHtg2jeAM9AF?locale=pt"
  }
];

export default function Pricing() {
  const { t } = useTranslation();

  useEffect(() => {
    const promoteKitScript = document.createElement('script');
    promoteKitScript.src = "https://cdn.promotekit.com/promotekit.js";
    promoteKitScript.setAttribute('data-promotekit', 'fffb177f-e2fc-4ebd-8163-704e5c5e7e64');
    promoteKitScript.async = true;

    const jQueryScript = document.createElement('script');
    jQueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js";
    jQueryScript.async = true;

    jQueryScript.onload = () => {
      // Just so we can confirm jQuery loaded perfectly
      console.log('jQuery loaded successfully.');
      initCustomScript(); // Call to initialize your custom script after jQuery is loaded
    };

    jQueryScript.onerror = () => {
      console.error('Failed to load jQuery script.');
    };

    promoteKitScript.onerror = () => {
      console.error('Failed to load PromoteKit script.');
    };

    document.body.appendChild(promoteKitScript);
    document.body.appendChild(jQueryScript);

    const initCustomScript = () => {
      const customScript = document.createElement('script');
      customScript.textContent = `
        $(document).ready(function(){
          setTimeout(function() {
            $('a[href^="https://buy.stripe.com/"]').each(function(){
              const oldBuyUrl = $(this).attr("href");
              const referralId = window.promotekit_referral || ""; // Ensure this is defined
              if (!oldBuyUrl.includes("client_reference_id")) {
                const newBuyUrl = oldBuyUrl + "&client_reference_id=" + referralId;
                $(this).attr("href", newBuyUrl);
              }
            });
            $("[pricing-table-id]").each(function(){
              $(this).attr("client-reference-id", window.promotekit_referral || ""); // Ensure this is defined
            });
            $("[buy-button-id]").each(function(){
              $(this).attr("client-reference-id", window.promotekit_referral || ""); // Ensure this is defined
            });
          }, 2000);
        });
      `;
      document.body.appendChild(customScript);
      console.log('Done!')
    };

    return () => {
      // Cleanup script elements if necessary
      document.body.removeChild(promoteKitScript);
      document.body.removeChild(jQueryScript);
      // Remove any custom scripts if necessary
    };
  }, []);
  
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
      	<img style={{ width: '150px' }} src={t('moneyBackLink')}/>
      </Box>

      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary" justifyContent="center">
          {t('menuOption3')}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('princing1')}
          <br />
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'Gold' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'Gold' ? 'primary.main' : undefined,
                background:
                  tier.title === 'Gold'
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color:
                      tier.title === 'Gold' ? 'primary.contrastText' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Gold' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={t('princingRecommended')}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color:
                      tier.title === 'Gold'
                        ? 'primary.contrastText'
                        : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    {t('princingSymbol')}{t(`princingHead.${index}.price`)}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; {t('princingPeriod')}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line, index1) => (
                  <Box
                    key={index1}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'Gold'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === 'Gold'
                            ? 'primary.contrastText'
                            : undefined,
                      }}
                    >
                      {t(`princingHead.${index}.list.${index1}`)}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"              
                  href={t(`princingHead.${index}.link`)}
                  target="_blank"
                >
                  {t('princingSignButton')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
