import { useEffect } from 'react';

const useFacebookSDK = (appId) => {
  useEffect(() => {
    // Check if the SDK has already been loaded
    const loadSDK = () => {
      if (window.FB) {
        initializeFB();
      } else {
        window.fbAsyncInit = initializeFB;
        loadScript();
      }
    };

    const initializeFB = () => {
      window.FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });

      window.FB.AppEvents.logPageView();
    };

    const loadScript = () => {
      ((d, s, id) => {
        let js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    };

    loadSDK();
  }, [appId]);
};

export default useFacebookSDK;
