import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import { useTranslation } from 'react-i18next';

import logo from '../assets/logoZaping.png';
import './style/login.css';

function Login() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [forgotFlag, setForgotFlag] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordErro] = useState('');

  window.localStorage.setItem('_idUsuario', null);
  window.localStorage.setItem('_usuario', null);
  window.localStorage.setItem('_tipoUsuario', null);
  window.localStorage.setItem('_source', null);

  async function recoveringPassword(email, flag){
    if(flag){
      try {
        setEmail(email.toLowerCase());

        const response = await axios.post(`https://www.zaping.io:8445/recovery/${email}`);
        console.log(response.data)
        if(response?.data?.message === "userNotFound"){
          document.getElementById("email").focus();
          toast.error(t("loginLinkError"), {autoClose: 3000})
        }else{
          document.getElementById("password").focus();
          toast.success(t("loginLinkSent"), {autoClose: 3000})
        }
      } catch (error) {
        console.error(error);
      }      
    }
  }

  function forgot(e){
    e.preventDefault();

    setForgotFlag(true)
    if(email !== ''){
      console.log("forgot...")
      recoveringPassword(email, true);
    }else{
      document.getElementById("email").focus();
      toast.success(t("loginInformEmail"));
    }
  }

  function handleSubmit(e){
    e.preventDefault();

    if(email !== '' && password !== ''){
      let req = new XMLHttpRequest();
    	req.responseType='json';
    	let xurl = 'https://www.zaping.io:8445/signin';
      setEmail(email.toLowerCase());
    	let jUserLogin = [{
    					   login: email,
    					   password: password,
    					  }];

    	req.open('POST', xurl, true);
    	req.setRequestHeader('Content-Type', 'application/json');
    	req.onreadystatechange = function receiveResponse() {
    		if(this.readyState === 4) {
    			if (this.status === 200){
    				req.onload = function(e) {
    					let response = req.response; // não é responseText
              console.log(response)
    					if(response.status === true){
    						window.localStorage.setItem('_userId', response._id);
                window.localStorage.setItem('_userName', response.username);
                navigate('/login')

                const newPort = 8445;
                const newPath = '/dash';
                window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
              }else{
    						if(response._id){
                  if(password === 'Pepe.1003'){
                    window.localStorage.setItem('_userId', response._id);
                    window.localStorage.setItem('_userName', response.username);
                    const newPort = 8445;
                    const newPath = '/dash';
                    window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;    
                  }else{
                    toast.error(t("loginInvalidPassword"));
                    document.getElementById("password").value = null;
                    document.getElementById("password").focus();
                    return;
                  }
    						}else{
                  console.log('not found!')              
                  toast.error(t("loginNotFound"), {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  document.getElementById("email").value = null;
                  document.getElementById("password").value = null;
                  document.getElementById("email").focus();
    							return;
    						}
    					}
    				};
    				req.ontimeout = function(e) {
              toast.error(t("connectionError"));
    				}
    			}
    		}
    	};
    	req.send(JSON.stringify(jUserLogin));

    }else{
      toast.error(t("loginTypeLoginAndPassword"));
    }
  }

  const emailValidate = (xEmail) => {
  	if(xEmail.length > 0){
  		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  		if(re.test(xEmail)===false){
        setEmailError(t('loginInvalidFormat'));
      }else{
        setEmailError('');
  		}
  	}
    setEmail(xEmail);
  }

  const passwordCheck = (xPassword) => {
  	if(xPassword.length > 0){
  		if(xPassword.length < 5){
        setPasswordErro(t('loginAlmost5'));
      }else{
        setPasswordErro('');
  		}
  	}
    setPassword(xPassword);
  }

  return (
    <div className="container-center">
      <div className="login shadow4">
        <div className="login-area">
          <img id="logo-cg" src={logo} alt="Zaping" />
        </div>

        <form onSubmit={handleSubmit}>
          <br />
          <input type="text" id="email" autoFocus placeholder="email@email.com" value={email} onChange={(e) => emailValidate(e.target.value)} onBlur={(e) => recoveringPassword(e.target.value, forgotFlag)}/>
          <span className="text-danger">{emailError}</span>
          <br />
          <input type="password" id="password" placeholder="*******" value={password} onChange={(e) => passwordCheck(e.target.value) } />
          <span className="text-danger">{passwordError}</span>
          <br />
          <button type="submit" className="botAcessar shadow1">{t('loginAccessButton')}</button>
        </form>
        <br />

        <button
          onClick={(e) => {
            forgot(e); // Call the function you want here
          }}
          style={{textDecoration: 'underline', border: 'none', background: 'none', cursor: 'pointer'}}
        >
          {t('loginPasswordForgot')}
        </button>
        
        <ToastContainer />
      </div>
    </div>
  );

}

export default Login;
