import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Customize these variables as needed
const phoneNumber = '+5541987848151'; // Include country code without '+', e.g., 18001234567
const message = encodeURIComponent('Oi, como o ZapinGPT funciona?');

const WhatsAppWidget = () => {
  const waLink = `https://wa.me/${phoneNumber}?text=${message}`;

  // Use media queries to adjust the icon size and position based on the screen size
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  
  const iconSize = isSmallScreen ? 40 : 60; // Smaller icons for smaller screens
  const positionRight = isSmallScreen ? '85%' : '95%'; // Closer to the edge for smaller screens
  const positionBottom = isSmallScreen ? '5%' : '3%'; // Adjust based on need

  return (
    <Box
      sx={{
        position: 'fixed',
        right: positionRight,
        bottom: positionBottom,
        zIndex: 100000, // Ensure it stays above most elements
      }}
    >
      <a href={waLink} target="_blank" rel="noopener noreferrer">
        <WhatsAppIcon sx={{ fontSize: iconSize, color: 'green' }} />
      </a>
    </Box>
  );
};

export default WhatsAppWidget