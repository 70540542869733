import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationPT from './locales/pt.json';

// The translations
const resources = {
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
};

let language = "pt"; // Default language

try {
  // Use HTTPS for the request to avoid mixed content issues
  //const response = await fetch(`https://ipinfo.io?token=0013989f469e5e`);
  //const data = await response.json();
  //const countryCode = data?.country;
  const countryCode = 'BR';
  console.log(countryCode);
  if(countryCode === 'BR'){
    language = "pt";
  }else{
    language = "en";
  }
} catch (error) {
  console.error("Failed to fetch user's locale", error);
  // Fallback language is already set to 'en'
}

async function initializeI18n(language) {
  // Initialize i18next after locale is fetched
  i18n
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
      resources,
      lng: language, // Dynamic language setting
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // React already safes from XSS
      },
    });
}

initializeI18n(language);

export default i18n;