import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router";

import styled from "styled-components";

import './style/catalog-list.css';
import './style/bootstrap.min.css';

import { AiOutlinePlus } from "react-icons/ai";
import { RiCloseCircleLine } from "react-icons/ri";

import { IconContext } from "react-icons";

import { toast } from 'react-toastify';

import Playground from "../components/Playground";

let emFiltro = false;
let recordsAll = [];
let setRecords = "";
let records = "";

async function deleteCatalog(param){
  await fetch("https://www.zaping.io:8445/catalog-delete/" + param, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })

  .then(()=>{
    toast.success('Catálogo apagado com sucesso!');
    window.location.reload();
  })
  .catch(error => {
    toast.error('Erro ao apagar catálogo!');
    console.log(error);
    return;
  });
}

const Record = (props) => (
  <div className="conteudo">
    <div className="row">
      <div className="col-2">
        <img id="productImage" src={props.oRecord.productImage} style={{ width: '75px', height: 'auto', objectFit: 'cover' }}></img>
      </div>
      <div className="col-9">
        <Link className="action" to={`/catalog-change/${props.oRecord._id}`} style={{ color: 'whiteSmoke !important'}}>
          <p id="desc" className="description">{props.oRecord.desc}</p>
        </Link>
      </div>

      <div className="col-1">
        <div className="form-group">
          <button onClick={() => deleteCatalog(props.oRecord._id)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
              <RiCloseCircleLine className="icon" />
          </button>
        </div>          
      </div>
    </div>
    <hr />
  </div>
);

export default function CatalogList() {
  [records, setRecords] = useState([]);
  let [recordsFull, setRecordsFull] = useState([]);
  let [key, setKey] = useState([]);

  const navigate = useNavigate();

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
      navigate("/login");
    }else{
      if(_prm) {
        return _prm;
      }else{
        navigate("/login");
      }
    }
  }

  let userId = getStorage("_userId");

  // This method fetches the records from the database.
  useEffect(() => {

    async function getRecords() {
      const response = await fetch('https://www.zaping.io:8445/catalog-list/' + userId);

      if (!response.ok) {
        const message = `ERRO: ${response.statusText}`;
        window.alert(message);
        return;
      }
      let records = await response.json();

      for (let r = 0; r < records.length; r++) {
        records[r].desc = records[r].productName + " Quantidade: " + records[r].quantity + " Valor: " + records[r].price
      }

      recordsAll = records;
      setRecords(records);
      setRecordsFull(records);
    }

    if(emFiltro === false){
      getRecords();
    }

  }, [records.length]);

 // This method will delete a record
  async function deleteRecord(id) {
    await fetch(`http://localhost:5000/${id}`, {
      method: "DELETE"
    });

    const newRecords = records.filter((el) => el._id !== id);
    setRecords(newRecords);
  }

  function recordList() {
    let oRecord, productImage;

    return records.map((record) => {
      productImage = record.productImage;
      productImage = productImage.replace('../', 'https://www.');
      oRecord = {_id: record._id,
                  desc: record.desc,
                  productImage: productImage
                }

      return (
        <Record
          record={record}
          oRecord={oRecord}
          deleteRecord={() => deleteRecord(record._id)}
          key={record._id}
        />
      );
    });
  }
  
 const RecordListContainer = styled.div`
    height: calc(100vh - 4rem); // Calculate the height to exclude the header
    overflow-y: auto; // Add vertical scrollbar when needed
    padding-right: 20px; // Add space for scrollbar
  `;

 const handleSearch = (param) => {
   setKey(param);

   if(param.length > 0){
     param = param.toLowerCase();
     let result = records.filter(rec => rec.desc.toLowerCase().includes(param))
     emFiltro = true;
     setRecords(result);
   }else{
     emFiltro = false;
     setRecords(recordsFull);
   }

 }

 function Filter() {
   return (
     <div className="conteudo">
       <div className="row">
         <div className="col-9">
           <input className="textoFiltro"
             type="text"
             id="key"
             placeholder="Buscar..."
             autoFocus="autoFocus"
             value={key}
             onChange={(e) => handleSearch(e.target.value)} />
         </div>

         <div className="col-1 butAdd">
           <IconContext.Provider value={{ size: "1.5em"}}>
             <Link to="/catalog-add" style={{ color: 'whitesmoke'}}><AiOutlinePlus /></Link>
           </IconContext.Provider>
         </div>
       </div>
     </div>
   )
 }

 return (
    <Container>
      <div className="container-list">
        <div className="playground">
          <Playground />
        </div>

        <div style={{ paddingLeft: '5px !important', marginLeft: '0px !important'}}>  
          <Filter />
          <RecordListContainer>  
            {recordList()}
          </RecordListContainer>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .playground  {
    position: sticky; /* Set position to sticky */
    top: 0; /* Stick to the top */
    height: 100vh; /* Set specific height */
    background-color: #212529;
  }
  .container-list {
    padding-left: 0%;
    margin-left: 0%;
    height: 100vh;
    width: 100vw;
    background-color: #212529;
    display: grid;
    grid-template-columns: 25% 75%;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
  }
`;