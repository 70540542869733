import React from 'react';

import styled from "styled-components";

import Playground from "../components/Playground";

// Create a functional component for the React client
const Redirect = () => {

  return (
    <Container>
      <div className="container-add">
        <div className="playground">
          <Playground />
        </div>

        <div className='redirect-text'>
          <div style={{ color: 'whiteSmoke'}}>
            <br />
            <p>Autorizado com Sucesso!</p>        
          </div>  
        </div>
      </div>
    </Container>
  );
};

export default Redirect;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .playground  {
    position: sticky; /* Set position to sticky */
    top: 0; /* Stick to the top */
    z-index: 999999999;
    height: 100vh; /* Set specific height */
    background-color: #212529;
  }
  .container-add {
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20% 80%; /* Use fractions of available space */
    @media screen  and (max-width: 1080px) {
      grid-template-columns: 5% 95%; /* Adjust for different screen size */
      font-size: 75%;
      #alertPhone {
        font-size: 100%;
      }
    }
  }
`;
