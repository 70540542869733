import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Moment from 'moment';
import { FaToggleOn } from "react-icons/fa";
import axios from "axios";

export default function CContacts({ contacts, changeChat }) {
  const [currentSelected, setCurrentSelected] = useState(0);
  const [contactsState, setContactsState] = useState(contacts);

  const changeCurrentChat = (index, contact) => {
    contacts[index].new = false;
    setCurrentSelected(index);
    changeChat(contact);
  };
  
  const changeAutoResponse = async (_id, autoResponse) => {
    console.log(_id)
    let contactsCopy = [...contacts]; // create a copy of contacts
    const index = contactsCopy.findIndex(contact => contact._id === _id);
    
    if (index !== -1) {
      contactsCopy[index].autoResponse = !autoResponse; // update autoResponse property
      let auto = true;
      if(autoResponse){
        auto = false;
      }
      try {
        console.log(`https://www.zaping.io:8445/autoresponse/${_id}/${auto}`);
        const respAuto = await axios.post(`https://www.zaping.io:8445/autoresponse/${_id}/${auto}`);
        setContactsState(contactsCopy);
      } catch (error) {
        console.error(error);
      }
    }
  };  

  useEffect(() => {
    if (contacts.length > 0) { // Check if there are contacts
      changeCurrentChat(0, contacts[0]); // Call the function
    }
  }, [contacts]); // Dependency array to avoid multiple calls, only call when contacts change  

  return (
    <>
        <Container>
          <div>
          </div>

          <div className="contacts">
            {contacts.map((contact, index) => {
              return (
                <div
                  key={contact._id}
                  id={contact._id}
                  className={`contact ${
                    index === currentSelected ? "selected" : ""
                  }`}
                  onClick={() => changeCurrentChat(index, contact)}
                >
                  <div>
                    <button id="butAutoResponse" type="button" className="button-autoresponse" onClick={() => changeAutoResponse(contact._id, contact.autoResponse)}>
                      <FaToggleOn
                        className={contact.autoResponse ? "butAct" : "butDeact"}
                        style={{ color: contact.autoResponse ? "#dcf8c6" : "#6c757d" }}
                      />
                    </button>
                  </div>

                  <div className={`username ${contact.new ? "new" : "old"}`}>
                    {contact.keywords?.map((item, index) => (
                      <div key={index} className="item" style={{ display: 'inline-block', marginRight: '5px' }}>
                        <button type="button" class="keywords">{item}</button>
                      </div>
                    ))}
                    <p className="keywords">{contact.lastMessage}</p>
                    <p>{Moment(contact.lastUse).format("DD-MM HH:mm")}</p>
                  </div>

                </div>
              );
            })}
          </div>
          
          <div>
          </div>
        </Container>

    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 1% 98% 1%;
  overflow: hidden;
  background-color: #212529;
  .new {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  .old {
    font-weight: normal !important;
  }
  .button-autoresponse {
    background-color: transparent !important;
    border-style: none;
    width: auto;
  }
  .butAct {
    font-size: 24px; 
    background-color: transparent !important;
    color: #dcf8c6;
  }
  .butDeact {
    font-size: 24px; 
    background-color: transparent !important;
    color: #6c757d;
  }  
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.2rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      background-color: #ffffff34;
      height: auto !important;
      
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 5px;
      padding-bottom: 0px;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      transition: 0.25s ease-in-out;

      .username {
        padding-top: 0px !important;
        h3 {
          font-weight: normal !important;
          color: whitesmoke;
          font-size: 12px !important;
          p {
            color: whitesmoke;
            font-size: 0.8rem !important;
          }    
        }
      }
    }
    .selected {
      background-color: #128c7e;
    }
  }

  p {
    color: whitesmoke !important;
    font-size: 13px;
  }

  .current-user {
    background-color: #0d0d30;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .username {
      h2 {
        color: #F8F8FF;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }

  button {
    color: whitesmoke !important;
  }

  .item {
    color: whitesmoke !important;
    height: 24px;
  }

  .keywords {
    background-color: transparent !important;
    font-size: 80%;
    color: whitesmoke !important;
    display: flex;
    padding-left: 5px;
    height: auto;
    font-style: italic;
  }
`;
