import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router";
import styled from "styled-components";

import Select from 'react-select';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import CurrencyInput from 'react-currency-input-field';

import { MdAddAPhoto } from "react-icons/md";

import './style/catalog-add.css';

import Playground from "../components/Playground";

let files;
let theFile;
let formData;
let formCreated;

export default function Edit() {
  const navigate = useNavigate();
  const params = useParams();
  let _id = params.id.toString();
  let price;

  let pictureFileExisting = [];

  let [pictureFile, setPictureFile] = useState({pictureFile: ""});
  const [active, setActive] = useState([]);
  const [activeChoice, setActiveChoice] = useState([]);
  const [value, setValue] = useState();
  
  const [form, setForm] = useState({
    userId: "",
    productName: "",
    productDescription: "",
    quantity: 0,
    price: "",
    active: "",
    productImage: ""
  });
  const [formattedPrice, setFormattedPrice] = useState({})
  
  let _userId = getStorage("_userId");

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
      navigate("/login");
    }else{
      if(_prm) {
        return _prm;
      }else{
        navigate("/login");
      }
    }
  }

  function changeImage(a) {
    files = a.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let aFileP = [];
    let lSpecial = false;
    let format = /[! ç@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
    filesArr.map((record) => {
      let str = record.name.toLowerCase();
      if(format.test(str)){
        lSpecial = true;
      }
      if(lSpecial){
        toast.error('Nome não pode conter acentos, espaços e caracteres especiais, favor renomear.', {autoClose: 4000})
        aFileP = [];
        return;
      }else{
        aFileP.push(record.name);
      }
    })

    theFile = a.target.files[0];
    const reader = new FileReader();
  
    reader.onload = function(event) {
      const img = document.getElementById('imageThumb');
      img.src = event.target.result;
    };
  
    reader.readAsDataURL(theFile);

    let eAP = document.getElementById('imageFileName');
    while (eAP.hasChildNodes()) {
      eAP.removeChild(eAP.firstChild);
    }

    setPictureFile(a.target.files);

    for (let p = 0; p < eAP.children.length; p = p + 2) {
      pictureFileExisting[0] = eAP.children[p].innerText;
    }
    let _aAP, dP;
    for (dP = 0; dP < aFileP.length; dP++) {
        if (pictureFileExisting.includes(aFileP[dP])){
        }else{
            _aAP = document.createElement('a');
            _aAP.innerHTML = aFileP[dP];
            _aAP.href = "https://zaping.io/bot/" + _userId + "_" + aFileP[dP];
            _aAP.target = "_blank";
            eAP.appendChild(_aAP);
            _aAP = document.createElement('br');
            eAP.appendChild(_aAP);
        }
    }
    formCreated = true;
    formData = new FormData(document.getElementById('formDataP'));
    formData.append('userId', _userId);
    formData.append('field', 'productImage');
    formData.append('pictureFileExisting', pictureFileExisting);
    formData.append("filesP", theFile);
  }
  
  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }
  
  useEffect(() => {

    async function fetchData() {
      const response = await fetch('https://www.zaping.io:8445/catalog/' + _id);

      if (!response.ok) {
        toast.error('ERRO!');
        return;
      }
      let record = await response.json();
      if (!record) {
        toast.error('Catálogo não Encontrado!');
        navigate("/oportunidade-lista");
        return;
      }
      let productImage = record[0].productImage;
      productImage = productImage.replace('../', 'https://www.');
      record[0].productImage = productImage;  
      setForm(record[0]);
      price = record[0].price;
      price = parseFloat(price);
      setValue(price);

      const dataActive = [
        {label: "Sim", value: true},
        {label: "Não", value: false}
       ];
      setActive(dataActive)
      setActiveChoice({label: record[0].active ? 'Sim' : 'Não', value: record[0].active}) 
    }

    fetchData();      

    return;
  }, [params.id, navigate]);

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    if(form.productName === ''){
      toast.error('Nome do Produto é Obrigatório!', {autoClose: 3000});
      return;
    }
    let price = document.getElementById("price").value.replace("R$", "");
    price = price.replace(".", "");
    price = price.replace(".", "");
    price = price.replace(",", ".");
    price = parseFloat(price);
    form.price = price;

    let reqP = new XMLHttpRequest();
    let sendTo;
    if(formCreated){
      let url = 'https://www.zaping.io:8445/catalog-change';
      formData.append("productName", form.productName);
      formData.append("productDescription", form.productDescription);
      formData.append("quantity", form.quantity);
      formData.append("price", price);
      formData.append("_id", _id);
      formData.append("active", form.active);
      reqP.open("POST", url, true);
      reqP.onreadystatechange = function receiveResponse() {
          if(this.readyState === 4) {
              if (this.status === 200){
                  reqP.onload = function(e) {
                    navigate("/catalog-list");
                  };
              }
          }
      };
  
      reqP.send(formData);    
    }else{
      try {
        let response = await fetch("https://www.zaping.io:8445/catalog-update/" + _id, {
          method: "POST",
          body: JSON.stringify(form),
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if(response.status === 200){
          toast.success("Catálogo alterado com sucesso!", {autoClose: 3000})
          navigate("/catalog-list");
        }
      }
      catch (error) {
        console.error(error);
      }    
    }
  }

  function updatePrice(value) {
    // Handle the change event here
    console.log(value)
    setValue(price);
  };  

  // This following section will display the form that takes the input from the user.
  return (
    <Container>

      <div className="container-add usuario-inclui">
        <Playground />

        <div>
          <form onSubmit={onSubmit}>
            <br />
            <div className="form-group">
              <div className="row">            
                <div className="col-12">
                  <label htmlFor="productName">Nome</label>
                  <input
                    type="text"
                    className="form-control"
                    id="productName"
                    value={form.productName}
                    onChange={(e) => updateForm({ productName: e.target.value })}
                  />
                  <br />
                </div>
                <div className="col-12">
                  <label htmlFor="productDescription">Descrição Detalhada</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="productDescription"
                    value={form.productDescription}
                    onChange={(e) => updateForm({ productDescription: e.target.value })}
                  />
                  <br />
                </div>

                <div className="col-3">
                  <label htmlFor="quantity">Quantidade</label>
                  <input 
                    type="number" 
                    className="form-control"
                    id="quantity" 
                    value={form.quantity}
                    onChange={(e) => updateForm({ quantity: e.target.value })}
                  />
                  <br />

                  <label htmlFor="price">Preço de Venda</label>
                  <CurrencyInput
                      id="price"
                      value={value}
                      name="price"
                      decimalsLimit={2}
                      intlConfig={{ locale: 'pt-Br', currency: 'BRL' }}
                      onValueChange={(e) => updatePrice({ price: value })}
                      className="form-control keep-right"
                    />
                  <br />

                  <label htmlFor="status" className="form-check-label">Ativo</label>
                  <select id="active"
                          onChange={e => setActiveChoice(e.target.value)}
                          className="form-control"
                          value={activeChoice}>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </div>
                
                <div className="col-3">
                  
                  <div>
                    <form id="formDataP" name="formDataP" method="post" encType="multipart/form-data">
                      <label htmlFor="productImage" className="fileInputLabel btn btn-primary shadow1">
                        <MdAddAPhoto />
                        <input type="file" 
                               id="productImage" 
                               name="filesP" 
                               accept=".jpg, .png, .jpeg"
                               onChange={(a) => changeImage(a)} 
                               style={{ display: "none" }} />
                      </label>
                    </form>
                  </div>

                  <div className="cNomeArquivoProtocolo" id="imageFileName">
                  </div>
          
                </div>

                <div className="col-6">
                 <img id="imageThumb" src={form.productImage}></img>
                </div>

              </div>

              <div>
                <br />
                <input
                  type="submit"
                  id="butSave"
                  value="Salvar"
                  className="btn btn-primary shadow1"
                />
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </form>
        </div>

        <ToastContainer />

      </div>
    </Container>

  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .container-add {
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 1fr 3fr; /* Use fractions of available space */
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 1fr 2fr; /* Adjust for different screen size */
    }
  }
`;
