import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { FcGoogle } from "react-icons/fc";
import { GrInstagram } from "react-icons/gr";
import { RiCloseCircleLine } from "react-icons/ri";
import { toast, ToastContainer } from 'react-toastify';
import Playground from "../components/Playground";
import useFacebookSDK from '../components/useFacebookSDK'; // Adjust the path as necessary

const APP_ID = '2737046116458807';

function getStorage(prm) {
  let _prm = window.localStorage.getItem(prm);
  if (_prm !== "null") {
    if (_prm) {
      return _prm;
    }
  }
  return null;
}

const userId = getStorage("_userId");

// Create a functional component for the React client
const Auth2 = () => {
  useFacebookSDK(APP_ID);
  console.log(APP_ID)

  const [isAuthorized, setIsAuthorized] = useState(false);

  const handleGoogleAuth = () => {
    // Directly redirect to the Google Auth URL
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&include_granted_scopes=true&response_type=code&client_id=534758288872-dn828afgnhr0v55814ng6jea2r064g7k.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fzaping.io%3A8445%2Foauth2callback&state=${userId}`;
    window.location.href = authUrl;  
  };
  
  const handleMetaAuth = () => {
    const authUrl = `https://www.instagram.com/oauth/authorize?client_id=1005742997927198&redirect_uri=https://zaping.io:8445/oauth2callback-meta&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments%2Cbusiness_content_publish&state=${userId}`;
    //business_basic
    //business_manage_messages
    //business_manage_comments
    //business_content_publish
    window.location.href = authUrl;  
  };

  async function handleGoogleRevoke() {
    const response = await fetch('https://www.zaping.io:8445/google-revoke/' + userId);
    if (!response.ok) {
      toast.error('ERRO ao revogar a conexão com o Google!');
      return;
    }
    let record = await response.json();
    if (record.status === 'ok') {
      toast.success("Desconectado do Google com Sucesso!", {autoClose: 3000})
    }else{
      toast.error('ERRO ao revogar a conexão com o Google!');
    }
  };

  async function handleMetaRevoke() {
    const response = await fetch('https://www.zaping.io:8445/meta-revoke/' + userId);
    if (!response.ok) {
      toast.error('ERRO ao revogar a conexão com a Meta!');
      return;
    }
    let record = await response.json();
    if (record.status === 'ok') {
      toast.success("Desconectado da Meta com Sucesso!", { autoClose: 3000 })
    } else {
      toast.error('ERRO ao revogar a conexão com a Meta!');
    }
  };

  return (
    <Container>
      <div className="container-add">
        <div className="playground">
          <Playground />
        </div>

        <div className="row">
  
          <div className="col-2">
            <br />
            <button onClick={handleGoogleAuth}><FcGoogle />Google</button>
            <button
              onClick={handleGoogleRevoke}
              style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent' }}>
              <RiCloseCircleLine className="icon" />
            </button>
          </div>

          {/* 
          
          <div className="col-10">
            <p style={{ color: 'whiteSmoke' }}>
              <br />
              IMPORTANT:
              <br />
              <br />
              ZapinGPT use and transfer to any other app of information received from Google APIs will adhere to the&nbsp;
              <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer" style={{ color: 'whiteSmoke', textDecoration: 'underline' }}>
                Google API Services User Data Policy
              </a>, including the Limited Use requirements.
            </p>

            <div>
              <label htmlFor="authorizeClients" style={{ color: 'whiteSmoke' }}>
                <input 
                  type="checkbox" 
                  id="authorizeClients" 
                  checked={isAuthorized} 
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '8px' }}
                />
                You also authorize your clients/users to access and check the availability of time slots in your Google Calendar to book new events through the Artificial Intelligence bot (GPT Assistant).
              </label>
            </div>
          </div>

          <div className="col-2">
            <br />
            <button onClick={handleMetaAuth}><GrInstagram />Instagram</button>
            <button
              onClick={handleMetaRevoke}
              style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent' }}>
              <RiCloseCircleLine className="icon" />
            </button>
          </div>
                    
          */}          

        </div>
      </div>

      <ToastContainer />
    </Container>
  );
};

export default Auth2;


const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  .playground {
    position: sticky;
    top: 0;
    z-index: 999999999;
    height: 100vh;
    background-color: #212529;
  }
  .container-add {
    overflow-y: auto;
    overflow-x: hidden; /* To prevent horizontal scrolling */
    height: 100vh;
    width: 100vw;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20vw 80vw;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    @media screen and (max-width: 1080px) {
      grid-template-columns: 5% 95%;
      font-size: 75%;
      #alertPhone {
        font-size: 100%;
      }
    }
  }
  .row {
    width: 100%; /* Set the width to be full */
    display: flex; /* Use flexbox for the row */
    .col-2 {
      flex: 0 0 20%; /* Flex property to make columns fit within the container */
    }
    .col-11 {
      flex: 0 0 80%;
    }
  }
`;
