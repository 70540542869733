import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router";

import styled from "styled-components";

import axios from "axios";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { IconContext } from "react-icons";
import { IoMdAdd } from "react-icons/io";
import { RiCloseCircleLine } from "react-icons/ri";

import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import Moment from 'moment';

import './style/trigger.css';

import Playground from "../components/Playground";

function getStorage(prm) {
  let _prm = window.localStorage.getItem(prm);
  if(_prm === "null"){
    //const newPort = 8445;
    //const newPath = '/';
    //window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
  }else{
    if(_prm) {
      return _prm;
    }else{
      //const newPort = 8445;
      //const newPath = '/';
      //window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
    }
  }
}

let userId = getStorage("_userId");

const redirectLogin = () => {
  const newPort = 8445;
  const newPath = '/';
  window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
};

let record;
let _brokersList;

export default function Broker() {
  const navigate = useNavigate();         
  const { t } = useTranslation();

  const [brokersList, setBrokerList] = useState([]);
  const [newBroker, setNewBroker] = useState({});

  const [form, setForm] = useState({
          userId: ""
        });
  const nextLead = 11000

  useEffect(() => {

    const fetchData = async () => {
      try {
        const url = `https://www.zaping.io:8445/query_broker/${userId}`;
        console.log('Making request to URL:', url);
    
        const response = await axios.get(url);
    
        // Assuming the existence of `record` in your response
        record = response.data;
        if (record) {
          console.log(record)
          setBrokerList(record);
        }else{
          //toast.error('Corretor não Encontrado!', {autoClose: 3000});
          return;
        }
    
      } catch (error) {
        console.error('Fetch error:', error);
        toast.error('Erro ao buscar corretor!');
        redirectLogin();
      }
    };
    
    if (userId) {
      fetchData();      
    } else {
      console.log('User ID is not set. Redirecting to login.');
      redirectLogin();
    }

  }, [userId, navigate]);

  const addBroker = (e) => {
    e.preventDefault();
    
    console.log(brokersList)

    let updatedBrokerList = [...brokersList];
    const uuid = uuidv4();
    let newBroker = {userId: userId, broker_id: "", broker: "", manager_id: "", team_id: "", region: "", lastCall: Date.now(), active: true}
    updatedBrokerList.push(newBroker);
    setBrokerList(updatedBrokerList);
  };
  
  const deleteBroker = (index, e) => {
    e.preventDefault();
  
    let updatedBrokerList = [...brokersList];
    updatedBrokerList.splice(index, 1); // Removes 1 element at the given index
    setBrokerList(updatedBrokerList);
    console.log(updatedBrokerList)
  };

  const Tooltip = ({ children, text }) => {
    return (
      <div className="tooltip-container">
        {children}
        <span className="tooltip-text">{text}</span>
      </div>
    );
  };

  function updateBroker(value, index) {
    let updatedBrokersList = [...brokersList];
    updatedBrokersList[index].broker = value.broker;
    setBrokerList(updatedBrokersList); // This was missing
  }

  function updateRegion(value, index) {
    let updatedBrokersList = [...brokersList];
    updatedBrokersList[index].region = value.region;
    setBrokerList(updatedBrokersList); // This was missing
  }

  function updateBrokerId(value, index) {
    let updatedBrokersList = [...brokersList];
    updatedBrokersList[index].broker_id = value.broker_id;
    setBrokerList(updatedBrokersList); // This was missing
  }

  function updateManagerId(value, index) {
    let updatedBrokersList = [...brokersList];
    updatedBrokersList[index].manager_id = value.manager_id;
    setBrokerList(updatedBrokersList); // This was missing
  }

  function updateTeamId(value, index) {
    let updatedBrokersList = [...brokersList];
    updatedBrokersList[index].team_id = value.team_id;
    setBrokerList(updatedBrokersList); // This was missing
  }

  function updateLastCall(value, index) {
    let updatedBrokersList = [...brokersList];
    updatedBrokersList[index].lastCall = value.lastCall;
    setBrokerList(updatedBrokersList); // This was missing
  }

  function updateActive(value, index) {
    console.log(value)

    let updatedBrokersList = [...brokersList];

    let activeStatus;
    if(value.active === 'true'){
      console.log('TRUE to FALSE')
      activeStatus = false;
    }
    if(value.active === 'false' || value.active === ''){
      console.log('FALSE to TRUE')
      activeStatus = true;
    }
    updatedBrokersList[index].active = activeStatus;
    updatedBrokersList[index].lastCall = Date.now();
    setBrokerList(updatedBrokersList); // This was missing
  }

  async function onSubmit(e) {
    e.preventDefault();

    let config = {
      method: 'post',
      url: `https://www.zaping.io:8445/update_brokers/${userId}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: brokersList
    };
    const response = await axios(config);
    console.log(response)
    if(response?.statusText === "OK"){
      toast.success("Atualizado com sucesso!", {autoClose: 3000})
    }else{
      toast.error('ERRO ao atualizar!', {autoClose: 3000});
    }
  }

  // This following section will display the form that takes the input from the user.
  return (
    <Container>

      <div className="container-add">
        <div className="playground">
          <Playground />
        </div>

        <div>
          <form onSubmit={onSubmit}>
            <br />
            <div className="form-group">

              <div className="row">
                <div className="col-10">
                </div>    

                <div className="col-2">
                    <label htmlFor="nextLead">Próximo Lead</label>
                    <input type="text" 
                            id="nextLead"
                            value={nextLead} 
                            onChange={(e) => {
                              updateBrokerId( { broker_id: e.target.value } );
                            }} 
                            className="form-control" />
                </div>    
              </div>    

              <div className="row">   
                <div className="col-12">
                  <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                    <Tooltip text="Clique para incluir.">
                      <button id="butAddField" onClick={(e) => addBroker(e)} className="butAddField"><IoMdAdd />Incluir</button>
                    </Tooltip>
                  </IconContext.Provider>
                </div>    
              </div>

              <div className="row">
                <div className="col-1">
                </div>    
                <div className="col-3">
                  <label>Corretor</label>
                </div>    
                <div className="col-1">
                  <label>Id Corretor</label>
                </div>    
                <div className="col-1">
                  <label>Id Gerente</label>
                </div>    
                <div className="col-1">
                  <label>Id do Time</label>
                </div>   
                <div className="col-1">
                  <label>Região</label>
                </div>    
                <div className="col-2">
                  <label>Última Transferência</label>
                </div>                    
                <div className="col-1">
                  <label></label>
                </div>    
              </div>    

              {brokersList?.map((broker, index) => (
                <div key={index}>

                  <div className="row">
                    <div className="col-1">
                      <Tooltip text="Clique para excluir.">
                        <button onClick={(e) => deleteBroker(index, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                          <RiCloseCircleLine className="icon" />
                        </button>           
                      </Tooltip>
                    </div>
                    
                    <div className="col-3">
                      <input type="text" 
                              value={broker?.broker} 
                              onChange={(e) => {
                                updateBroker( { broker: e.target.value}, index );
                              }} 
                              className="form-control" />
                    </div>
                   
                    <div className="col-1">                  
                      <input type="text" 
                              value={broker?.broker_id} 
                              onChange={(e) => {
                                updateBrokerId( { broker_id: e.target.value}, index );
                              }} 
                              className="form-control" />
                    </div>

                    <div className="col-1">                  
                      <input type="text" 
                              value={broker?.manager_id} 
                              onChange={(e) => {
                                updateManagerId( { manager_id: e.target.value}, index );
                              }} 
                              className="form-control" />
                    </div>

                    <div className="col-1">                  
                      <input type="text" 
                              value={broker?.team_id} 
                              onChange={(e) => {
                                updateTeamId( { team_id: e.target.value}, index );
                              }} 
                              className="form-control" />
                    </div>

                    <div className="col-1">
                      <input type="text" 
                              value={broker?.region} 
                              onChange={(e) => {
                                updateRegion( { region: e.target.value}, index );
                              }} 
                              className="form-control" />
                    </div>

                    <div className="col-2">                  
                      <input type="text" 
                              id="lastCall"
                              value={Moment(broker?.lastCall).format("DD-MM HH:mm")}                       
                              className="form-control" />

                    </div>

                    <div className="col-2"> 
                      <Tooltip text="Clique para alternar.">
                        <button id="active" 
                                value={broker?.active} 
                                onClick={(e) => {
                                  updateActive( { active: e.target.value}, index );
                                }} 
                                className={broker?.active ? 'butAct shadow1' : 'butDeact shadow1'}>
                                {broker?.active ? 'On' : 'Off'}
                        </button>
                      </Tooltip>                 
                    </div>
                  </div>
                </div>
              ))}              

              <div>
                <br />
                <input
                  type="submit"
                  value="Salvar"
                  id="butSave"
                  className="btn btn-primary shadow1"
                />
              </div>

            </div>
          </form>             
        </div>
 
        <ToastContainer />

      </div>
    </Container>

  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .playground  {
    position: sticky; /* Set position to sticky */
    top: 0; /* Stick to the top */
    z-index: 999999999;
    height: 100vh; /* Set specific height */
    background-color: #212529;
  }
  .container-add {
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20% 80%; /* Use fractions of available space */
    @media screen  and (max-width: 1080px) {
      grid-template-columns: 5% 95%; /* Adjust for different screen size */
      font-size: 75%;
      #alertPhone {
        font-size: 100%;
      }
    }
  }
`;
