import React, { useEffect, useState, useContext, PureComponent } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { BarChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Legend, Bar} from 'recharts';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import ChatContainer from "../components/ChatContainer";
import Contacts from "../components/Contacts";
import Playground from "../components/Playground";

import {SocketContext} from '../context/socket';

export default function Dash() {
  const [respDaily, setRespDaily] = useState(null);
  const [respUsage, setRespUsage] = useState(null);
  const [respMonthly, setRespMonthly] = useState(null);

  let socket = useContext(SocketContext);

  const navigate = useNavigate();

  const [currContact, setCurrContact] = useState("");
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  
  useEffect(() => {
    if (window.localStorage.getItem('_userId')) {
      setCurrentUser(window.localStorage.getItem('_userId'));
    } else {
//      navigate("/login");
      const newPort = 8445;
      const newPath = '/';
      window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;        
    }
  }, [navigate]);

  useEffect(() => {

    async function fetchData() {
      if (currentUser) {
        const respInit = await axios.get('https://www.zaping.io:8445/init-chat/' + currentUser);
        const response = respInit.data;
        console.log(response)
        
        setContacts(response);

        const responseDaily = await fetch('https://www.zaping.io:8445/dash_daily/' + currentUser);
        if (!responseDaily.ok) {
          toast.error('ERRO!');
          return;
        }
        if(responseDaily.status === 200){
          let respD = await responseDaily.json();
          
          const newRespD = respD.map(item => {
            const dateObj = new Date(item.date);
            const day = ('0' + dateObj.getUTCDate()).slice(-2);
            const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
            const newDate = `${day}-${month}`;
            return {
              Contatos: item.Contatos,
              Mensagens: item.Mensagens,
              date: newDate,
              userId: item.userId
            };
          });

          setRespDaily(newRespD);
        }

        const responseUsage = await fetch('https://www.zaping.io:8445/dash_usage/' + currentUser);
        if (!responseUsage.ok) {
          toast.error('ERRO!');
          return;
        }
        if(responseUsage.status === 200){
          let respU = await responseUsage.json()
          if(respU[0].Enviadas / respU[0].Plano > 0.9){
            let rest = respU[0].Plano - respU[0].Enviadas;
            toast.error('Restam ' + rest + ' mensagens/respostas no seu plano!', {closeOnClick: true});
          }
          setRespUsage(respU);
        }  

        const responseMonthly = await fetch('https://www.zaping.io:8445/dash_monthly/' + currentUser);
        if (!responseMonthly.ok) {
          toast.error('ERRO!');
          return;
        }
        if(responseMonthly.status === 200){
          let respM = await responseMonthly.json()
          setRespMonthly(respM);
        }        
      }
    }

    fetchData();
  }, [currentUser, socket]);

  const handleChatChange = (chat) => {
    // Handle chat change logic here
  };

  return (
    <div>
      <Container>
        <div className="container-chat">
          <div className="menu-area">
            <Playground />
          </div>

          <div className="dash-area">
            <div style={{ width: '100%', height: 230 }}>
              <ResponsiveContainer>
                <label>Diário</label>
                <ComposedChart
                  width={500}
                  height={400}
                  data={respDaily}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="date" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Mensagens" barSize={20} fill="#84a98c" />
                  <Area type="monotone" dataKey="Contatos" fill="#28a745" stroke="#28a745" />
                </ComposedChart>
              </ResponsiveContainer>
            </div>

            <div style={{ width: '100%', height: 230 }}>              
              <ResponsiveContainer>
                <label>Mensagens</label>
                <BarChart
                  data={respUsage}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Plano" fill="#86bbd8" />
                  <Bar dataKey="Enviadas" fill="#ffd670" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div style={{ width: '100%', height: 230 }}>              
              <ResponsiveContainer>
                <label>Mensal</label>
                <ComposedChart
                  width={500}
                  height={400}
                  data={respMonthly}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="month_year" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Contatos" stackId="a" fill="#28a745" />
                  <Bar dataKey="Mensagens" stackId="a" fill="#84a98c" />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="contact-area">  
            <Contacts contacts={contacts} changeChat={handleChatChange} />
            <ChatContainer currentChat={currentChat} socket={socket} currContact={currContact} contacts={contacts} setContacts={setContacts} />
          </div>

        </div>
      </Container>

      <ToastContainer />
      
    </div>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .container-chat {
    height: 100vh;
    width: 100vw;
    background-color: #212529;
    display: grid;
    grid-template-columns: 25% 45% 30%;

    @media screen and (max-width: 1080px) {
      grid-template-columns: 25% 45% 30%;
    }

    @media screen and (max-width: 720px) {
      // Mobile-first adjustments
      grid-template-columns: 1fr; // Stack on small screens
      grid-template-rows: 0% 1fr auto; // Adjust layout for vertical stacking
    }

    .menu-area {
      height: 100vh; /* Example fixed height */
      overflow: hidden;
    }
    .dash-area {
      height: 100vh; /* Example fixed height */
      overflow: hidden;
    }
    .contact-area {
      height: 100vh; /* Example fixed height */
      overflow: auto !important;
    }  
  }
`;

